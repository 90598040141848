























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import {
  GetFilteredUserListPayload,
  User
} from '../../../store/modules/admin/types/admin.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import { boldSearchKeywords } from '../../../utils/search.util';

@Component({
  components: { BaseLoading }
})
export default class SelectUserListComponent extends Vue {
  $refs!: {
    multiSelectUserSearchDropdown: any;
  };

  @Prop({ type: Boolean, default: false }) public isMultiSelect!: boolean;
  @Prop({ type: Number, default: 5 }) public limit!: number;
  @Prop() public selectedUsers!: User[];

  public selectedItems: User[] = this.selectedUsers ? this.selectedUsers : [];

  public isLoading: boolean = false;
  public showItemList: boolean = false;
  public searchInput: string = '';
  public filterBy: string = 'none';
  public filterOptions: any[] = [
    { label: 'First Name', value: 'firstName' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'Email', value: 'email' }
  ];
  public itemlimit: number = this.isMultiSelect ? this.limit : 1;

  get filteredItemList(): User[] {
    const selectedUserIds: number[] = this.selectedItems
      .filter((item) => item.userId)
      .map((item) => item.userId);

    return this.filteredUsers
      ? this.filteredUsers.filter(
          (user) => user.userId && !selectedUserIds.includes(user.userId)
        )
      : [];
  }

  get inputPlaceholder(): string {
    return !this.selectedItems || !this.selectedItems.length
      ? `e.g. Jane Doe, janedoe@example.com`
      : ``;
  }

  @Action('admin/getFilteredUserList')
  public getFilteredUserList!: (
    params: GetFilteredUserListPayload
  ) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.getFilterUsers)
  public getFilterUsersState!: ApiState;

  @State(({ admin }: RootState) => admin.filteredUsers)
  public filteredUsers!: User[];

  public toggleDropdown() {
    if (
      this.selectedItems.length !== this.itemlimit &&
      !this.$refs.multiSelectUserSearchDropdown.isActive
    ) {
      this.$refs.multiSelectUserSearchDropdown.toggle();
    }
  }

  public clearInput(): void {
    this.searchInput = '';
  }

  public onInputFocus(): void {
    this.getUserList();
    this.showItemList = true;
  }

  public mounted() {
    this.getUserList();
    this.showItemList = true;
  }

  public getUserList(): void {
    this.getFilteredUserList({
      searchInput: this.searchInput,
      filterBy: this.filterBy,
      page: 1,
      limit: 100
    });
    this.isLoading = true;
  }

  public onFilterSelect(value: string): void {
    this.filterBy = this.filterBy === value ? 'none' : value;
    this.getUserList();
  }

  public onEditItem(): void {
    if (!this.isMultiSelect) {
      this.selectedItems = [];
      this.getUserList();
    }
  }

  public handleRemoveItem(item: User): void {
    const dataIndex = this.selectedItems.findIndex(
      (selectedItem) => selectedItem.email === item.email
    );
    this.selectedItems.splice(dataIndex, 1);
    this.$emit('selectedUser', this.selectedItems);
  }

  public handleRemoveAll(): void {
    this.selectedItems.splice(0, this.selectedItems.length);
    this.$emit('selectedUser', this.selectedItems);
  }

  public userLabel(user: User): string {
    switch (this.filterBy) {
      case 'firstName':
        const firstName = boldSearchKeywords(user.firstName, this.searchInput);
        return `${firstName} ${user.lastName} -  ${user.email}`;
      case 'lastName':
        const lastName = boldSearchKeywords(user.lastName, this.searchInput);
        return `${user.firstName} ${lastName} -  ${user.email}`;
      case 'email':
        const email = boldSearchKeywords(user.email, this.searchInput);
        return `${user.firstName} ${user.lastName} -  ${email}`;
      default:
        const label = `${user.firstName} ${user.lastName} -  ${user.email}`;
        return boldSearchKeywords(label, this.searchInput);
    }
  }

  @Debounce(500)
  @Watch('searchInput')
  public onSearch(value: string): void {
    this.searchInput = value;
    this.getUserList();
  }

  @Watch('selectedItems')
  public onSelectItems() {
    // this.searchInput = '';
    if (!this.selectedItems.length) {
      this.$emit('selectedUser', null);
    } else {
      if (!this.isMultiSelect) {
        this.$emit('selectedUser', this.selectedItems[0]);
      } else {
        this.$emit('selectedUser', this.selectedItems);
      }
    }
  }

  @Watch('getFilterUsersState')
  public onGetFilterUsersState(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
    }
  }
}
