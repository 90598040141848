export const boldSearchKeywords = (value: string, searchInput: string) => {
  if (value && searchInput) {
    const regexTest = new RegExp(searchInput, 'gi');
    const matchIndex = regexTest.exec(`${value.toLowerCase()}`);

    if (matchIndex) {
      /**
       * '$&' is a special placeholder for the replace function that,
       * according to MDN, 'inserts the matched substring'.
       *
       * In other words, the function below is replacing the resolve
       * of the regex expression (which is case-insensitive) with a
       * string that is wrapped with a <b> tag.
       */
      value = value.replace(regexTest, '<b>$&</b>');
    }
    return value;
  }

  return value;
};
