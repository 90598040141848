import { createDecorator, VueDecorator } from 'vue-class-component';
import { debounce as _debounce } from 'lodash';

export const Debounce = (timeout: number): VueDecorator => {
  return createDecorator((options, key) => {
    if (!options.methods) {
      throw new Error(`Debounce Decorator Error`);
    }

    options.methods[key] = _debounce(options.methods[key], timeout);
  });
};
