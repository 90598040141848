






































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import {
  GetFilteredGroupListPayload,
  Group,
  GroupType
} from '../../../store/modules/admin/types/admin.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import { boldSearchKeywords } from '../../../utils/search.util';
@Component({
  components: { BaseLoading }
})
export default class SelectGroupListComponent extends Vue {
  $refs!: {
    multiSelectGroupSearchDropdown: any;
  };

  @Prop({ type: Boolean, default: false }) public isMultiSelect!: boolean;
  @Prop({ type: Number, default: 5 }) public limit!: number;
  @Prop() public isInstance!: boolean;
  @Prop() public isExceptions!: boolean;
  public isLoading: boolean = false;
  public showItemList: boolean = false;
  public selectedItems: Group[] = [];
  public groupName: string = '';
  public groupType: string = '';
  public itemlimit: number = this.isMultiSelect ? this.limit : 1;

  get filteredItemList() {
    const selectedGroupIds: number[] = this.selectedItems.map(
      (item) => item.id
    );
    return this.filteredGroups
      ? this.filteredGroups.filter(
          (item) => !selectedGroupIds.includes(item.id)
        )
      : [];
  }

  get inputPlaceholder(): string {
    return !this.selectedItems || !this.selectedItems.length
      ? `e.g. University of Adelaide`
      : ``;
  }

  @Action('admin/getGroupTypes')
  getGroupTypes!: () => void;

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[];

  @Action('admin/getGroupsByNameAndType')
  public getGroupsByNameAndType!: (
    params: GetFilteredGroupListPayload
  ) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.getFilterGroups)
  public getFilterGroupsState!: ApiState;

  @State(({ admin }: RootState) => admin.filteredGroups)
  public filteredGroups!: Group[];

  get infoText(): string {
    if (this.isInstance) {
      let message =
        'Start looking for an instance(s) in the selected module to grant access.';
      if (this.isMultiSelect) {
        message += ' Max 5 selections at once.';
      }
      return message;
    } else {
      return 'Start looking for a group by name or group type';
    }
  }

  public clearInput(): void {
    this.groupName = '';
  }

  public toggleDropdown() {
    if (
      this.selectedItems.length !== this.itemlimit &&
      !this.$refs.multiSelectGroupSearchDropdown.isActive
    ) {
      this.$refs.multiSelectGroupSearchDropdown.toggle();
    }
  }

  public onInputFocus(): void {
    this.getGroupList();
    this.showItemList = true;
  }

  public getGroupList(): void {
    this.getGroupsByNameAndType({
      groupName: this.groupName,
      groupType: this.groupType,
      page: 1,
      limit: 100
    });
    this.isLoading = true;
  }

  public onFilterSelect(value: string): void {
    this.groupType = this.groupType === value ? '' : value;
    this.getGroupList();
  }

  public onEditItem(): void {
    if (!this.isMultiSelect) {
      this.selectedItems = [];
      this.getGroupList();
    }
  }

  public handleRemoveItem(item: Group): void {
    const dataIndex = this.selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    this.selectedItems.splice(dataIndex, 1);
    this.$emit('selectedGroup', this.selectedItems);
  }

  public handleRemoveAll(): void {
    this.selectedItems.splice(0, this.selectedItems.length);
    this.$emit('selectedGroup', this.selectedItems);
  }

  public mounted(): void {
    this.getGroupTypes();
    this.getGroupList();
    this.showItemList = true;
  }

  public groupLabel(group: Group): string {
    const groupName = boldSearchKeywords(group.name, this.groupName);
    return group.types ? `${groupName} (${group.types.name})` : groupName;
  }

  @Debounce(500)
  @Watch('groupName')
  public onGroupNameSearch(value: string): void {
    this.groupName = value;
    this.getGroupList();
  }

  @Watch('selectedItems')
  public onSelectItems() {
    // this.groupName = '';
    if (!this.selectedItems.length) {
      this.$emit('selectedGroup', null);
    } else {
      if (!this.isMultiSelect) {
        this.$emit('selectedGroup', this.selectedItems[0]);
      } else {
        this.$emit('selectedGroup', this.selectedItems);
      }
    }
  }

  @Watch('getFilterGroupsState')
  public onGetFilterGroupsState(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
    }
  }
}
