var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('div',[_c('b-dropdown',{ref:"multiSelectUserSearchDropdown",class:_vm.$style.dropdown,attrs:{"expanded":"","multiple":"","trap-focus":"","close-on-click":!_vm.isMultiSelect,"aria-role":"list"},on:{"change":_vm.clearInput},nativeOn:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown($event)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._t("customTrigger",[_c('div',{class:_vm.$style.trigger},[[_c('ul',{class:_vm.$style.selectedItemContainer},[_vm._l((_vm.selectedItems),function(item,index){
var _obj;
return _c('li',{key:(item + "_" + index)},[(!_vm.isMultiSelect && _vm.selectedItems.length === 1)?_c('p',[_vm._v(" "+_vm._s(_vm.selectedItems[0].firstName)+" "+_vm._s(_vm.selectedItems[0].lastName)+" - "+_vm._s(_vm.selectedItems[0].email)+" ")]):_c('p',{class:( _obj = {}, _obj[_vm.$style.tagBox] = true, _obj[_vm.$style.newUserBox] = !item.userId, _obj )},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" "),(!item.userId)?_c('span',[_vm._v(" (drafted invite) ")]):_vm._e(),_c('i',{class:['mdi mdi-close', _vm.$style.removeIconBtn],on:{"click":function($event){return _vm.handleRemoveItem(item)}}})])])}),(_vm.selectedItems.length < _vm.itemlimit)?_c('b-input',{class:_vm.$style.customInput,attrs:{"placeholder":_vm.inputPlaceholder},on:{"change":_vm.onInputFocus},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchInput"}}):_vm._e()],2),(_vm.selectedItems.length > 0 && _vm.isMultiSelect)?_c('p',{class:_vm.$style.clearAllBtnText,on:{"click":_vm.handleRemoveAll}},[_vm._v(" Clear all ")]):_vm._e(),(_vm.selectedItems.length === _vm.itemlimit && !_vm.isMultiSelect)?_c('div',{class:_vm.$style.editIconBtn,on:{"click":function($event){return _vm.onEditItem()}}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1):_vm._e()]],2)])]},proxy:true}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},[(_vm.selectedItems.length < _vm.itemlimit)?[_c('div',{class:_vm.$style.dropdownItem},[_c('div',{class:_vm.$style.filterContainer},[_c('p',{class:_vm.$style.filterLabel},[_vm._v("Filter by:")]),_vm._l((_vm.filterOptions),function(option,index){return _c('div',{key:index,class:[
                _vm.$style.filterOptions,
                _vm.filterBy === option.value
                  ? _vm.$style.selectedFilterOptions
                  : null
              ]},[_c('label',{on:{"click":function($event){return _vm.onFilterSelect(option.value)}}},[_vm._v(" "+_vm._s(option.label)+" ")])])})],2)]),(_vm.isLoading)?_c('BaseLoading'):(
            !_vm.isLoading && _vm.filteredItemList && _vm.filteredItemList.length > 0
          )?_c('div',_vm._l((_vm.filteredItemList),function(item,index){return _c('b-dropdown-item',{key:index,class:_vm.$style.dropdownItem,attrs:{"value":item,"aria-role":"listitem"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.userLabel(item))}}),(!item.emailVerified)?_c('span',{class:_vm.$style.unverifiedUserBox},[_vm._v("Unverified")]):_vm._e()])}),1):_c('div',[_c('p',{class:_vm.$style.noDataContainer},[_vm._v("No Data Found")])])]:_vm._e()],2)],1),_c('p',{class:_vm.$style.infoText},[_vm._v("Start looking for a user by name or email")])])}
var staticRenderFns = []

export { render, staticRenderFns }